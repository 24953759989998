<template>
  <div class="about__page">
    <transition>
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "active",
    };
  },
  created() {
    console.log("created");
    setTimeout(function() {
      console.log("enter on settimeout function");
      window.scrollTo(0, 100);
    }, 1);
  },
};
</script>

<style scoped>
.territorio_nav {
  margin-bottom: 2em;
}

a {
  color: #5d3f36;
  cursor: pointer;
  text-decoration: none;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
}

.router-link {
  color: #5d3f36;
}
</style>
